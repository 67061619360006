//terminalDetailsRules 终端详情页面验证规则相关
const checkTerminalName = async(rule, value) => {
    // var reg = new RegExp("^[A-Za-z0-9\u4e00-\u9fa5]+$");
    var reg = new RegExp("[ ]");
    var value = value.trim();
    if (value.length < 1) {
        return Promise.reject('请输入终端名称');
    }
    if (reg.test(value)) {
        // return Promise.reject('仅支持汉字、字母或数字 例如:沃尔玛大连金马路店');
        return Promise.reject('不可输入空格');
    }
}
const checkTerminalCode = async(rule, value) => {
    var reg = new RegExp("^[A-Za-z0-9]+$");
    var value = value.trim();

    if (!reg.test(value) && value.length != 0) {
        return Promise.reject('仅支持字母或数字');
    }
}

const checkProvince = async(rule, value) => {
        if (value.length < 1) {
            return Promise.reject('请选择省市区');
        }
        if (value.length < 2) {
            return Promise.reject('请完整选择对应城市及区县');
        }
        if (value.length < 3) {
            return Promise.reject('请完整选择对应的区县');
        }
    }
    //不可仅是数字
const notJustNumbers = async(rule, value) => {
        // (?!\d+$)[\dA-Za-z\u4e00-\u9fa5]{1,20}
        value = value ? value : ''
        var reg = new RegExp("(?!\d+$)[\dA-Za-z\u4e00-\u9fa5]{1,20}");
        var value = value.trim();
        if (!reg.test(value) && value.length != 0) {
            return Promise.reject('不可仅输入数字');
        }
    }
    const checkChannel = async(rule, value) => {
        // console.log(value);
        if (!value||value.length<1) {
            return Promise.reject('请选择渠道');
        }
    }
    const checkChannelName = async(rule, value) => {
        // console.log(value);
        if (!value||value.length<1) {
            return Promise.reject('请选择渠道');
        }
    }
    //不可仅是数字且必选项
const notJustNumbersAnd = async(rule, value) => {
    value = value ? value : ''
    var reg = new RegExp("(?!\d+$)[\dA-Za-z\u4e00-\u9fa5]{1,20}");
    var value = value.trim();
    if (value.length < 1) {
        return Promise.reject('请输入');
    }
    if (!reg.test(value) && value.length != 0) {
        return Promise.reject('不可仅输入数字');
    }
}

export const terminalDetailsRules = {
    terminalName: [{
            required: true,
            trigger: "blur",
            validator: checkTerminalName,
        },
        { min: 0, max: 200, message: "最多200字符", trigger: "blur" },
    ],
    
    terminalCode: [{
            validator: checkTerminalCode,
            trigger: "blur",
        },
        { min: 0, max: 32, message: "最多32个字符", trigger: "blur" },
    ],
    terminalOriginCode: [{
        validator: async(rule, value) => {
            var reg = /^[\u4e00-\u9fa5a-zA-Z0-9]+$/;
            if (value) {
                var value = value.trim();
                if (!reg.test(value) && value.length != 0) {
                    return Promise.reject('仅支持字母、数字和汉字');
                }  
            }
        },
        trigger: "blur",
        },
        { min: 0, max: 50, message: "最多50个字符", trigger: "blur" },
    ],
    province: [{
        required: true,
        validator: checkProvince,
        trigger: "blur",
    }, ],
    defaultProvince: [{
        required: true,
        validator: checkProvince,
        trigger: "blur",
    }, ],
    defaultChannel: [{
        required: true,
        validator: checkProvince,
        trigger: "blur",
    }, ],
    town: [{
            trigger: "blur",
        },
        { max: 50, message: "最多50个字符", trigger: "blur" },
    ],
    address: [{
            required: true,
            message: "请输入终端地址",
            trigger: "blur",
        },
        { min: 0, max: 200, message: "最多200个字符", trigger: "blur" },
    ],
    kindsItem1: [{
            trigger: "blur",
            validator: notJustNumbers,
        },
        { min: 0, max: 20, message: "最多20个字符", trigger: "blur" },
    ],
    kindsItem2: [{
            trigger: "blur",
            validator: notJustNumbers,
        },
        { min: 0, max: 20, message: "最多20个字符", trigger: "blur" },
    ],
    channel: [{
            required: true,
            trigger: "blur",
            validator: notJustNumbersAnd,
        },
        { min: 0, max: 20, message: "最多20个字符", trigger: "blur" },
    ],
    channelCode :[{
        required: true,
        trigger: "blur",
        validator: checkChannel,
    },
],
channelName :[{
    required: true,
    trigger: "blur",
    validator: checkChannelName,
},
],
    childChannel: [{
            required: true,
            trigger: "blur",
            validator: notJustNumbersAnd,
        },
        { min: 0, max: 20, message: "最多20个字符", trigger: "blur" },
    ],
    phone: [{
            trigger: "blur",
        },
        { min: 0, max: 20, message: "最多20字符", trigger: "blur" },
    ],
    licenseCode: [{
            trigger: "blur",
        },
        { min: 0, max: 32, message: "最多32字符", trigger: "blur" },
    ],
    licenseName: [{
            trigger: "blur",
        },
        { min: 0, max: 32, message: "最多32字符", trigger: "blur" },
    ],
    licenseAddress: [{
            trigger: "blur",
        },
        { min: 0, max: 100, message: "最多100字符", trigger: "blur" },
    ],
    licenseDay: [{
            trigger: "blur",
        },
        { min: 0, max: 32, message: "最多32字符", trigger: "blur" },
    ],
    licenseType: [{
            trigger: "blur",
        },
        { min: 0, max: 32, message: "最多32字符", trigger: "blur" },
    ],
    licenseExpiry: [{
            trigger: "blur",
        },
        { min: 0, max: 32, message: "最多32字符", trigger: "blur" },
    ],
};


//品牌 的rules
const checkSort = async(rule, value) => {
    var reg = new RegExp("^[0-9]+$");
    var value = value.trim();
    if (value.length === 0) {
        return
    }
    if (!reg.test(value)) {
        return Promise.reject('仅支持输入数字');
    }
}
const checkbrandName = async(rule, value) => {
    if (value === undefined) {
        value = ""
    }
    var reg = new RegExp("^[A-Za-z0-9\u4e00-\u9fa5]+$");
    var value = value.trim();
    if (value.length < 1) {
        return Promise.reject('请输入名称');
    }
    if (!reg.test(value)) {
        return Promise.reject('仅支持汉字、字母、数字');
    }
}
export const brandRules = {
    brandName: [{
            required: true,
            validator: checkbrandName,
            trigger: "blur",
        },
        { min: 0, max: 50, message: "最多50字符", trigger: "blur" },
    ],
    sort: [{
            trigger: "blur",
            validator: checkSort,
        },
        { min: 0, max: 4, message: "最多4位数字", trigger: "blur" },
    ],
}

//单品详情页面的rules 及验证规则
const checkspName = async(rule, value) => {
    if (value === undefined) {
        value = ""
    }
    // var reg = new RegExp("^[*A-Za-z0-9\u4e00-\u9fa5]+$");
    var value = value.trim();
    if (value.length < 1) {
        return Promise.reject('请输入名称');
    }
    // if (!reg.test(value)) {
    //     return Promise.reject('仅支持汉字、字母、数字或 *');
    // }
}
const checkspCode = async(rule, value) => {

    if (value === undefined) {
        value = ""
    }
    var reg = new RegExp("^[A-Za-z0-9]+$");
    var value = value.trim();
    if (!reg.test(value) && value !== '') {
        return Promise.reject('仅支持字母或数字');
    }
}
const checkspBarCode = async(rule, value) => {
    if (value === undefined) {
        value = ""
    }
    var reg = new RegExp("^[A-Za-z0-9]+$");
    var value = value.trim();
    if (value.length < 1) {
        return Promise.reject('请输入条形码');
    }
    if (!reg.test(value)) {
        return Promise.reject('仅支持字母或数字');
    }
}
const spNickName = async(rule, value) => {
    if (value === undefined) {
        value = ""
    }
    var reg = new RegExp("^[A-Za-z0-9\u4e00-\u9fa5]+$");
    var value = value.trim();
    if (!reg.test(value) && value !== '') {
        return Promise.reject('仅支持汉字、字母或数字');
    }
}
const salesPrice = async(rule, value) => {

    if (value === undefined) {
        value = ""
    }
    var reg = new RegExp("^[0-9]+$");
    var value = value.trim();
    if (!reg.test(value) && value !== '') {
        return Promise.reject('仅可输入数字');
    }
}
const salesPriceA = async(rule, value) => {

    if (value === undefined) {
        value = ""
    }
    var reg = /(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/
    var value = value.trim();
    if (!reg.test(value) && value !== '') {
        return Promise.reject('仅可输入数字,最多两位小数');
    }
}




export const SingleProductRules = {
    spName: [{
            required: true,
            trigger: "blur",
            validator: checkspName,
        },
        { min: 0, max: 200, message: "最多200字符", trigger: "blur" },
    ],
    spCode: [{
            trigger: "blur",
            validator: checkspCode,
        },
        { min: 0, max: 32, message: "最多32字符", trigger: "blur" },
    ],
    spBarCode: [{
            required: true,
            trigger: "blur",
            validator: checkspBarCode,
        },
        { min: 0, max: 20, message: "最多20字符", trigger: "blur" },
    ],
    spNickName: [{
            trigger: "blur",
            validator: spNickName,
        },
        { min: 0, max: 20, message: "最多20字符", trigger: "blur" },
    ],
    stockUnitCode: [{
        required: true,
        trigger: "blur",
        message: "请选择单位",
    }, ],
    salesPrice: [{
            trigger: "blur",
            validator: salesPriceA,
        },
        { min: 0, max: 9, message: "请输入0-999999999之间的数字", trigger: "blur" },
    ],
    shelfLife: [{
            trigger: "blur",
            validator: salesPrice,
        },
        { min: 0, max: 5, message: "请输入0-99999之间的数字", trigger: "blur" },
    ],
    sizeHigh: [{
            trigger: "blur",
            validator: salesPrice,
        },
        { min: 0, max: 5, message: "请输入0-99999之间的数字", trigger: "blur" },
    ],
    sizeLong: [{
            trigger: "blur",
            validator: salesPrice,
        },
        { min: 0, max: 5, message: "请输入0-99999之间的数字", trigger: "blur" },
    ],
    sizeWide: [{
            trigger: "blur",
            validator: salesPrice,
        },
        { min: 0, max: 5, message: "请输入0-99999之间的数字", trigger: "blur" },
    ],
}