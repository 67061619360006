<template>
  <Modal
    v-model:visible="visible"
    centered
    @ok="handleOk"
    class="modal barndmodal"
    :confirmLoading="confirmLoading"
    :zIndex="200"
    width="800px"
    :okButtonProps="{ disabled: formState.brandName == '' }"
  >
    <template #title>
      <DecorateModalTitle :modalTitle="title" />
    </template>
    <div class="outcell" style="padding: 0 220px 0 138px">
      <Form
        :model="formState"
        :rules="rules"
        ref="formRef"
        :label-col="{ style: { width: '80px' } }"
      >
        <Form-item label="品牌名称" name="brandName">
          <Input v-model:value="formState.brandName" placeholder="请输入" />
        </Form-item>
        <Form-item label="上级品牌">
          <Cascader
            style="width: 360px"
            v-model:value="formState.brandParCode"
            :options="options"
            placeholder="请选择 若不选择则为一级品牌"
            :load-data="loadData"
            change-on-select
          />
        </Form-item>

        <div style="position: relative">
          <Form-item label="排序" name="sort">
            <Input
              v-model:value="formState.sort"
              placeholder="请输入权重 若不输入默认为1"
            />
          </Form-item>
          <div
            style="
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              right: -20px;
              color: #d9d9d9;
              font-size: 14px;
            "
          >
            <Popover>
              <template #content>
                <span style="color: #8c8c8c">权重值越大，排序越靠前</span>
              </template>
              <QuestionCircleOutlined class="icon" title="" />
            </Popover>
          </div>
        </div>
        <Form-item label="品牌介绍" name="memo">
          <textarea
            v-model="formState.memo"
            cols="70"
            rows="5"
            maxlength="120"
            placeholder=""
            style="width: 360px"
            class="textarea"
          ></textarea>
        </Form-item>
        <Form-item label="品牌logo" name="imgLogo" style="margin-bottom: 50px">
          <div class="tips">支持PNG或JPG格式，大小应小于5M</div>
          <Upload
            accept=".jpg,.jpeg,.png"
            name="file"
            list-type="picture-card"
            @change="handleChange"
            :before-upload="beforeUpload"
            :customRequest="customRequest"
            v-if="imgFlag && notUploaded"
            v-model:file-list="filelist"
            :showUploadList="false"
          >
            <div class="upload-text">
              <PictureOutlined style="font-size: 20px" />
              <div class="ant-upload-text">点击上传</div>
            </div>
          </Upload>
          <div class="imgBox" v-else>
            <Image
              :src="firstImg"
              class="previewPhoto"
              style="width: 120px; height: 120px; object-fit: cover"
            />
            <div class="mask"></div>
            <div class="editBox">
              <span class="span" @click="previewImg">
                <EyeFilled />
                查看
              </span>
              <span style="margin-left: 12px; color: #999">|</span>
              <Upload
                accept=".jpg,.jpeg,.png"
                class="notUpload"
                name="file"
                @change="handleChange"
                :before-upload="beforeUpload"
                :customRequest="customRequest"
                :showUploadList="false"
                v-model:file-list="filelist"
              >
                <span class="span"> <EditFilled /> 编辑 </span>
              </Upload>
            </div>
            <CloseCircleFilled class="imgIcon" @click="changeImg" />
          </div>
        </Form-item>
      </Form>
    </div>
  </Modal>
</template>
<script>
import { defineComponent } from "vue";
import {
  Modal,
  Form,
  Input,
  Cascader,
  Upload,
  message,
  Image,
  Popover,
} from "ant-design-vue";
import {
  PlusOutlined,
  CloseCircleFilled,
  LoadingOutlined,
  EyeFilled,
  EditFilled,
  PictureOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons-vue";
import {
  imageUpload,
  allBrandList,
  addBrand,
  brandDownList,
} from "@/api/terminalList.js";
import { brandRules } from "@/rules/rules";
import DecorateModalTitle from "@/components/modification/DecorateModalTitle.vue";
const { Item } = Form;
export default defineComponent({
  components: {
    DecorateModalTitle,
    Modal,
    Form,
    FormItem: Item,
    Input,
    Cascader,
    Upload,
    PlusOutlined,
    CloseCircleFilled,
    LoadingOutlined,
    EyeFilled,
    EditFilled,
    PictureOutlined,
    Image,
    Popover,
    QuestionCircleOutlined,
  },
  props: {
    brandList: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      visible: false,
      title: "新增品牌",
      formState: {
        brandParCode: [],
        brandName: "",
        imgLogo: "",
        memo: null,
        sort: "",
      },
      rules: brandRules,
      //品牌的联级选择选项
      options: [],
      cityCurrent: 0,
      notUploaded: true,
      nowDate: "",
      fileUrl: "",
      confirmLoading: false,
      firstImg: "",
      imgFlag: true,
      filelist: [],
    };
  },
  methods: {
    showModal(recode) {
      this.formState = {
        brandParCode: [],
        brandName: "",
        imgLogo: "",
        memo: null,
        sort: "",
      };
      if (recode) {
        this.title = `编辑品牌`;
      }
      this.visible = true;
      if (this.$refs.formRef) {
        this.$refs.formRef.resetFields();
      }
      allBrandList().then((res) => {
        let level = 0;
        this.convertKey(res.data.data, level);
        let newOptions = res.data.data.map((item) => {
          let checkIsLeaf;
          if (item.childBrandList.length) {
            checkIsLeaf = false;
          } else {
            checkIsLeaf = true;
          }
          return {
            ...item,
            value: item.brandCode,
            label: item.brandName,
            isLeaf: checkIsLeaf,
          };
        });
        this.options = newOptions;
      });
    },
    convertKey(arr, level) {
      level++;
      arr.forEach((item) => {
        item.level = level;
        if (item["childBrandList"].length != 0) {
          item.level = level;
          this.convertKey(item.childBrandList, level);
        }
      });
      return arr;
    },
    handleOk() {
      this.$refs.formRef.validate().then(() => {
        this.confirmLoading = true;
        addBrand({
          brandParCode: this.formState.brandParCode.slice(-1).toString(),
          brandName: this.formState.brandName
            ? this.formState.brandName.trim()
            : "",
          memo: this.formState.memo ? this.formState.memo.trim() : "",
          sort: this.formState.sort,
          imgLogo: this.fileUrl,
        })
          .then((res) => {
            if (res.data.success) {
              this.fileUrl = "";
              //成功之后先重新获取列表
              allBrandList().then((res) => {
                let level = 0;
                this.convertKey(res.data.data||[], level);
                let newOptions = res.data.data.map((item) => {
                  let checkIsLeaf;
                  if (item.childBrandList.length) {
                    checkIsLeaf = false;
                  } else {
                    checkIsLeaf = true;
                  }
                  return {
                    ...item,
                    value: item.brandCode,
                    label: item.brandName,
                    isLeaf: checkIsLeaf,
                  };
                });
                this.options = newOptions;

                message.success("新增品牌成功");
                this.$emit("todoTeload");
                this.$emit("myevent");
                this.visible = false;
                this.confirmLoading = false;
                this.formState = {
                  brandParCode: [],
                  brandName: "",
                  imgLogo: "",
                  memo: "",
                  sort: "",
                };
              });

              this.notUploaded = true;
            } else {
              this.confirmLoading = false;
            }
          })
          .catch(() => {
            this.confirmLoading = false;
          });
      });
    },
    //联级选择懒加载
    loadData(selectedOptions) {
      let targetOption = selectedOptions[selectedOptions.length - 1];
      targetOption.loading = true; // load options lazily
      brandDownList(targetOption.brandCode).then(() => {
        targetOption.loading = false;

        let newOptions = targetOption.childBrandList.map((item) => {
          let checkIsLeaf;
          if (item.childBrandList.length) {
            checkIsLeaf = false;
          } else {
            checkIsLeaf = true;
          }
          if (item.level === 3) {
            checkIsLeaf = true;
          }
          return {
            ...item,
            value: item.brandCode,
            label: item.brandName,
            isLeaf: checkIsLeaf,
          };
        });
        targetOption.children = newOptions;
        this.options = [...this.options];
      });
    },
    //上传图片
    handleChange(info) {
      if (info.fileList.length < 1) {
        this.notUploaded = true;
      }
    },
    //检验图片格式
    beforeUpload(file, fileList) {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        message.error("上传图片应为PNG或JPG格式,请重新上传", 4);
        fileList.length = 0;
      }
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        message.error("图片大小应小于5M,请重新上传", 4);
        fileList.length = 0;
      }
      return isJpgOrPng && isLt5M;
    },
    customRequest(data) {
      const formData = new FormData();
      let name = data.file.name;
      name = name.substring(name.lastIndexOf(".") + 1);
      formData.append("file", data.file);
      formData.append("module", `brand`);
      formData.append("scene", `logo`);
      formData.append("format", name);
      this.confirmLoading = true;
      imageUpload(formData).then((res) => {
        this.fileUrl = res.data.data.fileUrl;
        this.firstImg = res.data.data.absoluteFileUrl;
        this.confirmLoading = false;
        this.notUploaded = false;
        data.onSuccess();
      });
    },
    getDate() {
      var date = new Date();
      var seperator1 = "";
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var strDate = date.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      var currentdate = year + seperator1 + month + seperator1 + strDate;
      return currentdate;
    },
    changeImg() {
      this.imgFlag = true;
      this.notUploaded = true;
      this.fileUrl = "";
    },
    //预览图片
    previewImg() {
      let photo = document.querySelector(".previewPhoto");
      setTimeout(() => {
        if (photo) {
          photo.click();
        }
      }, 0);
    },
  },
  mounted() {},
});
</script>
<style lang="scss" scoped>
.modal {
  border-radius: 20px;
}
input {
  width: 70%;
}
.tips {
  color: #ccc;
  text-align: center;
  position: absolute;
  bottom: 12px;
  left: 140px;
}
.textarea {
  border-radius: 5px;
  border-color: #e1e1e1;
}
.uploadBtn {
  width: 120px;
  height: 120px;
  background: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}
.upload-text {
  color: #bfbfbf;
  text-align: center;
  line-height: 20px;
  font-weight: 400;
  .ant-upload-text {
    margin-top: 10px;
  }
}

.status {
  position: absolute;
  top: -50px;
  left: -50px;
}

.imgBox {
  width: 120px;
  position: relative;
  .imgIcon {
    position: absolute;
    top: -11px;
    right: -11px;
    cursor: pointer;
    color: #008bcf;
    display: none;
    font-size: 16px;
  }
  .editBox {
    font-size: 12px;
    color: #ffffff;
    font-weight: 600;
    width: 100%;
    text-align: center;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    display: none;
    .span {
      cursor: pointer;
      color: #fff;
    }
    .span:nth-last-of-type(1) {
      margin-left: 12px;
    }
  }
}
.mask {
  width: 120px;
  height: 120px;
  opacity: 0.6;
  background: #000000;
  position: absolute;
  top: 0;
  left: 0;
  display: none;
}
.imgBox:hover .imgIcon {
  display: block;
}
.imgBox:hover .mask {
  display: block;
}
.imgBox:hover .editBox {
  z-index: 10;
  display: block;
}
</style>
<style lang="scss" >
.barndmodal .ant-upload:not(.notUpload .ant-upload) {
  width: 120px !important;
  height: 120px !important;
  background: #fafafa;
  border-radius: 2px;
}
.barndmodal .ant-select-selection-placeholder {
  height: 40px !important;
  line-height: 40px !important;
}
.barndmodal .ant-select-selector {
  height: 40px !important;
  line-height: 40px !important;
}
.barndmodal .ant-select-selection-item {
  height: 40px !important;
  line-height: 40px !important;
}
.barndmodal input {
  width: 360px;
  height: 40px;
  line-height: 40px;
  border-radius: 2px;
  border: 1px solid #d9d9d9;
}
</style>